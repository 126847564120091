<template>
  <octo-table
    show-filters
    :show-filters-collapse="false"
    :action="endpoints.DATATABLES.callCenterLeads"
    :fields="fields"
    :filters="filters"
    :name="name"
    @onResetFilters="resetFilters"
  >
    <template v-slot:name="data">
      <div class="text-truncate">
        {{ data.row | optional('lead.registry.surname') }}
        {{ data.row | optional('lead.registry.name') }}
      </div>
      <div class="small">
        Data: <span class="text-primary">{{ data.row.lead.date_in | date }}</span>
      </div>

    </template>

    <template v-slot:status="data">
      <badge :type="data.row.status | leadStatusColor" class="text-uppercase">
        {{ $t('callcenter.status.' + data.row.status) }}
      </badge>
    </template>

    <template slot="custom-filters">

    </template>
  </octo-table>
</template>

<script>
import OctoTable from "@/components/octo-table/OctoTable";
import OctoIcon from "@/components/octo-icon/OctoIcon";
import {endpoints} from "@/routes/endpoints";
import Badge from "@/components/Badge";
import {Option, Select} from "element-ui";
import {mapGetters} from "vuex";
import Campaign from "@/models/campaign";

export default {
  name: "CampaignLeadDatatable",
  components: {Badge, OctoIcon, OctoTable, [Select.name]: Select, [Option.name]: Option},
  data() {
    return {
      endpoints: endpoints,
      filters: {
        campaignIds : [this.campaign.id]
      },
      fields: [
        {prop: 'name', label: 'name', slot: true, align: 'left'},
        {prop: 'status', label: 'status', width: 150, slot: true, align: 'center'},
      ],
    }
  },
  props: {
    campaign: {
      type: Object,
      default: () => this.$_.cloneDeep(Campaign)
    }
  },
  methods: {
    resetFilters: function () {
      this.filters = {
        campaignIds : [this.campaign.id]
      };
    },
  },
  beforeMount() {
    this.filters = this.getFilters(this.name);
    this.filters.campaignIds = [this.campaign.id]
  },
  computed: {
    ...mapGetters({
      getFilters: 'common/datatableFilters',
    }),

    name: function () {
      return "campaignLeadDatatable-" + this.campaign.id
    },
  }
}
</script>

<style scoped>

</style>
